import React from 'react'
import { useSelector } from 'react-redux'
import DOMPurify from "dompurify"

export default function HomeServices() {
    const { tableData } = useSelector((state) => state.splashSlice)

    return (
        <>
            {tableData?.[0]?.ServiceDetail &&
                <section className="section-spacing pt-0">
                    <div className="how-it-works-wrap">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-md-9 col-lg-6 mx-auto splitting animated">
                                    <div class="section-title text-center light mb-0">
                                        {/* <span>Services</span> */}
                                        <h2 class="wow word">Services <strong></strong></h2>
                                        {/* <p>Faff about only a quid blower I don’t want no agro bleeding chimney pot burke tosser cras
                                            nice one boot fanny.!</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {tableData?.[0]?.ServiceDetail?.map((element, index) => (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="how-it-works wow pulse" data-wow-delay="0.1s">
                                            <div className="count">{index + 1}</div>
                                            <div className="content">
                                                <h6>{element?.Title}</h6>
                                                {/* <p className='text-justify ellipsiscss'>{element?.Description}</p> */}

                                                <p className='text-justify ellipsiscss homeservice'>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            element?.Description
                                                        ),
                                                    }} style={{ marginBottom: '1px' }}>
                                                    </div>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}


