import React from 'react'
import { useSelector } from 'react-redux'
import DOMPurify from "dompurify"
import { AboutUs } from '../../Process';
import { NavLink } from 'react-router-dom';
import { mainRouteInitial } from '../../constants';

export default function HomeAboutUs() {
    const { tableData } = useSelector((state) => state.splashSlice)

    return (
        <>
            {
                tableData?.[0]?.AboutUsDetail &&
                // <section className="section-spacing gdr-light marketing-welcome section-padding paddingTopBottom">
                <section className="gdr-light section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 mb-0">
                                <div className="section-title text-start splitting animated mb-0">
                                    {/* <span>What We Offer</span> */}
                                    <h2 className="wow word">{tableData?.[0]?.AboutUsDetail?.[0]?.Title} <strong></strong></h2>
                                    <p>
                                        <div className='text-justify ellipsiscss_des' dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                tableData?.[0]?.AboutUsDetail?.[0]?.Description
                                            ),
                                        }} style={{ marginBottom: '1px' }}>
                                        </div>
                                        <div className='homeaboutus'>
                                            <NavLink to={`/${mainRouteInitial}/AboutUs`}>Read More
                                            </NavLink>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-5 order-lg-first">
                                <div className="img-character">
                                    {/* <img className='aboutusheight faqs-imgs' src={AboutUs + tableData?.[0]?.AboutUsDetail?.[0]?.Image} alt /> */}
                                    <img className='aboutusheight' src={AboutUs + tableData?.[0]?.AboutUsDetail?.[0]?.Image} alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
