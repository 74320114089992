import React, { useRef, useState } from 'react'
import { Button, Form, Modal } from "react-bootstrap";
import { ApplyFor, ApplyforaPosition, ApplyJob, Cancel, CurrentLocation, EmailID, Experience, JobDescription, JobPlace, MobileNo, Name, Resume, Skills } from '../../constants';
import { CMFormInput, MultiSelectRadio, PopUpMessage, RadioButtonList } from '../../methodmaster/jsxcompnentmethod';
import { useFormik } from "formik";
import { EnterApplyFor, EnterApplyType, EnterCurrentLocation, EnterEmailID, EnterExperience, EnterGender, EnterJobDescription, EnterMobileNo, EnterName, EnterProperEmailID, EnterProperMobileNo, EnterResume, EnterValidFile } from '../../constants/validationlabel';
import { emailIDRegex, mobileNumberRegex } from '../../data/RejexValidation';
import * as Yup from "yup";
import { handleKeyDownNumber } from '../../methodmaster/helpermethod';
import { API_URL } from '../../Process';

export default function ApplyJobModel({ isOpen, onClose, JobPostID, ApplyType }) {
    const ResumeRef = useRef()
    // const [modalMode, setModelMode] = useRef(isOpen)

    // console.log("isOpen", isOpen);
    // console.log("JobPostID", JobPostID);

    const genderData = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" }
    ];

    const setApplyJobData = async (fetchvalues) => {
        try {
            // JSON.stringify({
            //     JobPostID: JobPostID,
            //     Name: fetchvalues.Name || "",
            //     MobileNo: fetchvalues.MobileNo || "",
            //     EmailID: fetchvalues.EmailID || "",
            //     Gender: fetchvalues.Gender || "",
            //     CurrentLocation: fetchvalues.CurrentLocation || "",
            //     ApplyType: ApplyType || "",
            //     ApplyFor: fetchvalues.ApplyFor || "",
            //     Experience: fetchvalues.Experience || "",
            //     JobDescription: fetchvalues.JobDescription || "",
            //     Department: fetchvalues.Department || "",
            //     JobPlace: fetchvalues.JobPlace || "",
            //     Skills: fetchvalues.Skills || ""
            // })

            const form = new FormData();
            form.append("JobPostID", JobPostID || "");
            form.append("Name", fetchvalues?.Name || "");
            form.append("MobileNo", fetchvalues?.MobileNo || "");
            form.append("EmailID", fetchvalues?.EmailID || "");
            form.append("Gender", fetchvalues?.Gender || "");
            form.append("CurrentLocation", fetchvalues?.CurrentLocation || "");
            form.append("ApplyType", ApplyType || "");
            form.append("ApplyFor", fetchvalues?.ApplyFor || "");
            form.append("Experience", fetchvalues?.Experience || "");
            form.append("JobDescription", fetchvalues?.JobDescription || "");
            form.append("Department", fetchvalues?.Department || "");
            form.append("JobPlace", fetchvalues?.JobPlace || "");
            form.append("Skills", fetchvalues?.Skills);
            form.append("Resume", fetchvalues?.Resume);


            const res = await fetch(
                `${API_URL}ApplyJob/setApplyJob`,
                {
                    method: "post",
                    body: form
                }
            );
            const result = await res.json();
            if (result.status == true) {
                formik.resetForm();
                await PopUpMessage(result.message, "success");
                onClose();
            } else {
                await PopUpMessage(result.message, "error");
            }
        } catch (error) {
            await PopUpMessage(error.message, "error");
        }
    };

    const formik = useFormik({
        initialValues: {
            JobPostID: "",
            Name: "",
            MobileNo: "",
            EmailID: "",
            Gender: "Male",
            CurrentLocation: "",
            ApplyFor: "",
            Experience: "",
            JobDescription: "",
            JobPlace: "",
            Skills: "",
            Resume: "",
        },
        validationSchema: Yup.object({
            Name: Yup.string().required(EnterName),
            MobileNo: Yup.string().matches(mobileNumberRegex, EnterProperMobileNo).required(EnterMobileNo),
            EmailID: Yup.string().matches(emailIDRegex, EnterProperEmailID).required(EnterEmailID),
            Gender: Yup.string().required(EnterGender),
            CurrentLocation: Yup.string().required(EnterCurrentLocation),
            ApplyFor: ApplyType == "FromQuery" ? Yup.string().required(EnterApplyFor) : Yup.string(),
            Experience: Yup.string().required(EnterExperience),
            JobDescription: ApplyType == "FromQuery" ? Yup.string().required(EnterJobDescription) : Yup.string(),
            Resume: Yup.mixed()
                .test("fileType", EnterValidFile, (value) => {
                    return (
                        !value ||
                        ["application/pdf"].includes(value.type)
                    );
                }).required(EnterResume)
        }),
        onSubmit: async (values) => {
            // debugger;
            // console.log("values------------>", values);
            setApplyJobData(values)
        },
    });

    const handleError = (name) => {
        return formik.touched[name] && formik.errors[name] ? (
            <div className="text-danger text-left" style={{ fontWeight: "600" }}>{formik.errors[name]}</div>
        ) : null;
    };

    const closeModal = () => {
        formik.resetForm();
        onClose();
    };

    return (
        <Modal
            show={isOpen}
            size="xl"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>{ApplyforaPosition}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit} className="row">
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={Name}
                            id={"Name"}
                            required={true}
                            temptVal={formik.values.Name}
                            type={"text"}
                            maxLength={200}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={MobileNo}
                            id={"MobileNo"}
                            required={true}
                            temptVal={formik.values.MobileNo}
                            type={"text"}
                            onKeyDown={handleKeyDownNumber}
                            maxLength={10}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={EmailID}
                            id={"EmailID"}
                            required={true}
                            temptVal={formik.values.EmailID}
                            type={"text"}
                            maxLength={80}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <label>Gender <span className='text-danger'>*</span></label>
                        <RadioButtonList
                            options={genderData}
                            // name="gender"
                            name="Gender"
                            // selectedValue={selectedValue}
                            // temptVal={formik.values.Gender}
                            onChange={formik.handleChange}
                            value={formik.values.Gender}
                        // handleDispatch={(e) => {
                        //     formik.handleChange(e);
                        // }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={CurrentLocation}
                            id={"CurrentLocation"}
                            required={true}
                            temptVal={formik.values.CurrentLocation}
                            type={"text"}
                            maxLength={300}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    {(ApplyType == 'FromQuery') &&
                        <>
                            <div className="col-md-4 mb-4">
                                <CMFormInput
                                    InputLabel={ApplyFor}
                                    id={"ApplyFor"}
                                    required={true}
                                    temptVal={formik.values.ApplyFor}
                                    type={"text"}
                                    maxLength={200}
                                    handleError={handleError}
                                    handleDispatch={(e) => {
                                        formik.handleChange(e);
                                    }}
                                />
                            </div>
                            <div className="col-md-4 mb-4">
                                <CMFormInput
                                    InputLabel={JobDescription}
                                    id={"JobDescription"}
                                    textArea={true}
                                    maxLength={300}
                                    row={"1"}
                                    temptVal={formik.values.JobDescription}
                                    col={"4"}
                                    handleError={handleError}
                                    handleDispatch={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    required={true}
                                />
                            </div>
                        </>
                    }
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={Experience}
                            id={"Experience"}
                            required={true}
                            temptVal={formik.values.Experience}
                            type={"text"}
                            maxLength={20}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>

                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={JobPlace}
                            id={"JobPlace"}
                            required={false}
                            temptVal={formik.values.JobPlace}
                            type={"text"}
                            maxLength={60}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={Skills}
                            id={"Skills"}
                            textArea={true}
                            maxLength={80}
                            row={"1"}
                            temptVal={formik.values.Skills}
                            col={"4"}
                            handleError={handleError}
                            handleDispatch={(e) => {
                                formik.handleChange(e);
                            }}
                        />
                    </div>
                    <div className="col-md-4 mb-4">
                        <CMFormInput
                            InputLabel={Resume}
                            id={Resume}
                            imgReg={ResumeRef}
                            required={true}
                            type={"file"}
                            accept="application/pdf"
                            handleError={handleError}
                            handleDispatch={(event) => {
                                console.log(
                                    event.currentTarget.files[0],
                                    "event"
                                );
                                if (!event.target.value) {
                                    formik.setFieldValue("Resume", "");
                                    return;
                                }
                                formik.setFieldValue(
                                    "Resume",
                                    event.currentTarget.files[0]
                                );
                            }}
                        />
                    </div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-primary applynow"><span className="outer-wrap"><span data-text={ApplyJob} onClick={(e) => { formik.handleSubmit(e); }}>{ApplyJob}</span></span></button>
                <button type="button" className="btn btn-danger applynow"><span className="outer-wrap"><span data-text={Cancel} onClick={() => { closeModal() }}>{Cancel}</span></span></button>

                {/* <Button variant="primary" onClick={(e) => { formik.handleSubmit(e); }}>{ApplyJob}</Button>
                <Button variant="danger" onClick={onClose}>{Cancel}</Button> */}
            </Modal.Footer>
        </Modal>
    )
}
