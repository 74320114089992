export const mainRouteInitial = "Onus"
export const IntialPageCount = "6"

export const Name = "Name"
export const MobileNo = "Mobile No"
export const EmailID = "Email ID"
export const Gender = "Gender"
export const CurrentLocation = "Current Location"
export const ApplyFor = "Apply For"
export const Experience = "Experience"
export const JobDescription = "Job Description"
export const JobPlace = "Current Job Place"
export const Skills = "Skills"
export const Resume = "Resume"
export const ApplyforaPosition = "Apply for a Position"
export const ApplyJob = "Apply Job"
export const Cancel = "Cancel"