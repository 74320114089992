import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from "dompurify"
import { fecthContactUsData } from '../../redux/slices/contactusSlice';

export default function ContactUs() {
    const dispatch = useDispatch()
    const { tableData } = useSelector((state) => state.contactusSlice)

    useEffect(() => {
        bindContactUsData();
    }, [])

    const bindContactUsData = () => {
        dispatch(fecthContactUsData())
    }

    return (
        <>
            {
                tableData &&
                <>
                    <div>
                        <section className='section-padding'>
                            <div className="container contact-details-wrap">
                                <div className="row">
                                    {tableData?.[0]?.Address &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="contact-details wow fadeInUp" data-wow-delay="0.1s">
                                                <div className="icon-style-border-bottom">
                                                    <div className="icon"><i className="bi bi-geo-alt" /></div>
                                                    <div className="text">
                                                        <h6>Our Location</h6>
                                                        <p>{tableData?.[0]?.Address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {tableData?.[0]?.MobileNo1 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="contact-details wow fadeInUp" data-wow-delay="0.2s">
                                                <div className="icon-style-border-bottom">
                                                    <div className="icon"><i className="bi bi-phone" /></div>
                                                    <div className="text">
                                                        <h6>Call Us</h6>
                                                        <p>
                                                            {`+91 ${tableData?.[0]?.MobileNo1}`}<br />
                                                            {tableData?.[0]?.MobileNo2 && `+91 ${tableData?.[0]?.MobileNo2}`}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {tableData?.[0]?.EmailID1 &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="contact-details wow fadeInUp" data-wow-delay="0.3s">
                                                <div className="icon-style-border-bottom">
                                                    <div className="icon"><i className="bi bi-envelope" /></div>
                                                    <div className="text">
                                                        <h6>Email Us</h6>
                                                        <p><a href="javascript:void(0)"><span className="__cf_email__">{tableData?.[0]?.EmailID1}</span></a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {tableData?.[0]?.LinkedinLink &&
                                        <div className="col-md-6 col-lg-4">
                                            <div className="contact-details wow fadeInUp" data-wow-delay="0.3s">
                                                <div className="icon-style-border-bottom">
                                                    <div className="icon"><i className="bi bi-linkedin" /></div>
                                                    <div className="text">
                                                        <h6>Linkedin</h6>
                                                        <p><a href={tableData?.[0]?.LinkedinLink}>Linkedin</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className="map-frame"><iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" />
                            </div> */}
                        </section>
                    </div>

                    {/*<div className="container">
                        <div className="footer-subscribe">
                            <div className="row align-items-center">

                                 <div className="col-lg-3 col-md-4">
                                    <div className="social-icons">
                                        <ul className="list-unstyled">
                                            {tableData?.[0]?.FacebookLink && <li><a target='_blank' href={tableData?.[0]?.FacebookLink}><i className="bi bi-facebook" /></a></li>}
                                            {tableData?.[0]?.LinkedinLink && <li><a target='_blank' href={tableData?.[0]?.LinkedinLink}><i className="bi bi-linkedin" /></a></li>}
                                            {tableData?.[0]?.TwitterLink && <li><a target='_blank' href={tableData?.[0]?.TwitterLink}><i className="bi bi-twitter-x" /></a></li>}
                                        </ul>
                                    </div>
                                </div> 
                                 <div className="col-md-12">
                                    <div className="align-items-center row form-inputs">
                                        <div className="col-md-6 text-lg-end">
                                            <h6>Let's Get Connected</h6>Start Finding Your Dream Job
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-flex">
                                                <button type="submit" className="btn btn-secondary bordered"><i className="bi bi-send" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>*/}

                </>
            }
        </>
    )
}