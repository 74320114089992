import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobPostData, handlePrevious, handleSetData } from '../../redux/slices/jobPostSlice';
import { JobPost } from '../../Process';
import DOMPurify from "dompurify"
import PaginationButton from '../../commoncomponent/PaginationButton';
import { useNavigate } from 'react-router-dom';
import ApplyJobModel from '../../commoncomponent/ApplyJobModel';

export default function JobPostDetail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tableData, pageCount, jobpostid } = useSelector((state) => state.jobPostSlice)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getJobPostID, setJobPostID] = useState('');
    const [getApplyType, setApplyType] = useState('');

    useEffect(() => {
        // debugger;
        // console.log("jobpostid", jobpostid);
        if (jobpostid) {
            bindJobPostData(jobpostid);
        } else {
            navigate(`/Onus/JobSeeker`);
        }
    }, [])

    const openModal = (tempJobPostID, tempApplyType) => {
        setIsModalOpen(true);
        setJobPostID(tempJobPostID)
        setApplyType(tempApplyType)
    }
    const closeModal = () => setIsModalOpen(false);


    const bindJobPostData = () => {
        dispatch(fetchJobPostData({ pageID: 1, pageCount, uniqueID: "", IsPagination: "false", jobpostid: jobpostid }))
    }

    return (
        <>
            {tableData &&
                <>
                    <section className="section-spacing pt-5">
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="square-top"><img src="/assets/images/square_large.svg" alt /></div> */}
                                <div className="col-md-12 col-lg-8 blog-layout-standard">
                                    <div className="blog-wrap-rounded blog-singular-page">
                                        {/* <div className="img-wrap">
                                        <img src="/assets/images/blogs/blog_img_1.jpg" alt />
                                    </div> */}

                                        <div className="blog-content row">
                                            <div className="author-sidebar-widget hstack col-md-10">
                                                {/* <img src={JobPost + tableData?.[0]?.Image} alt /> */}
                                                <h3><a href="javascript:">{tableData?.[0]?.Title}</a> <span></span></h3>
                                            </div>
                                            <div className="col-md-2 verticlecenter">
                                                <button type="button" className="btn btn-default applynow" onClick={() => openModal(tableData?.[0]?.JobPostID, 'FromJobPost')}><span className="outer-wrap"><span data-text="Apply Now">Apply Now</span></span></button>
                                            </div>
                                            <section>
                                                {/* <div className="post-pinned"><i className="bi bi-pin-angle" /></div> */}
                                                {/* <div className="meta-tag">Featured Story</div> */}
                                                <p className='text-justify'>
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: DOMPurify.sanitize(
                                                            tableData?.[0]?.Description
                                                        ),
                                                    }} style={{ marginBottom: '1px' }}>
                                                    </div>
                                                </p>
                                                {tableData?.[0]?.Qualification &&
                                                    <>
                                                        <div className='light wow fadeInUp'>
                                                            <h5> Qualification</h5>
                                                            <p className='text-justify'>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(
                                                                        tableData?.[0]?.Qualification
                                                                    ),
                                                                }} style={{ marginBottom: '1px' }}>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                {tableData?.[0]?.CTC &&
                                                    <>
                                                        <div className='light wow fadeInUp'>
                                                            <h5>CTC</h5>
                                                            <p className='text-justify'>
                                                                {tableData?.[0]?.CTC}
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                                {tableData?.[0]?.KeySkills &&
                                                    <>
                                                        <div className='light wow fadeInUp'>
                                                            <h5> Key Skills</h5>
                                                            <p className='text-justify'>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: DOMPurify.sanitize(
                                                                        tableData?.[0]?.KeySkills
                                                                    ),
                                                                }} style={{ marginBottom: '1px' }}>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <aside className="sidebar-spacer row">
                                        <div className="sidebar-primary col-lg-12 col-md-6">
                                            <div className="widget-wrap wow fadeInUp">
                                                <div className='blog-content row'>
                                                    <h3 className="widget-title col-md-10">Job Summary</h3>
                                                    <div class="post-pinned postpinnedcss col-md-2"><i class="bi bi-pin-angle"></i></div>
                                                </div>
                                                <div className="blog-post-sidebar">
                                                    <ul className="list-unstyled icons-listing-bordered theme-secondary mb-0">
                                                        {tableData?.[0]?.Category &&
                                                            <li><span className='font-bold'>Category:</span> <span>{tableData?.[0]?.Category}</span>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.Location &&
                                                            <li><span className='font-bold'>Location:</span> <span>{tableData?.[0]?.Location}</span>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.JobType &&
                                                            <li><span className='font-bold'>JobType:</span> <span>{tableData?.[0]?.JobType}</span>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.Experience &&
                                                            <li><span className='font-bold'>Experience:</span> <span>{tableData?.[0]?.Experience}</span>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.Vacancy &&
                                                            <li><span className='font-bold'>Vacancy:</span> <span>{tableData?.[0]?.Vacancy}</span>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.Industry &&
                                                            <li><span className='font-bold'>Industry:</span> <span>{tableData?.[0]?.Industry}</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="widget-wrap">
                                                <h3 className="widget-title">For Best Job</h3>
                                                <div className="have-question">
                                                    <img src="/assets/images/sidebar_widget_care.png" alt />
                                                    <p>Get Best Matched Jobs. Add Resume NOW!</p>
                                                    <button type="button" className="btn btn-secondary" onClick={() => openModal(null, 'FromQuery')}><span className="outer-wrap"><span data-text="Upload Resume">Upload Resume</span></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </section >
                    <ApplyJobModel isOpen={isModalOpen} onClose={closeModal} JobPostID={(getJobPostID)} ApplyType={getApplyType} />
                </>
            }
        </>
    )
}
