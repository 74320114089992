import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import HeaderNav from '../HeaderNav'
import Banner from '../Banner'
import Footer from '../Footer'
import PageHeader from '../../commoncomponent/PageHeader'
import { NavBardata } from '../../data/NavBardata'
import { fecthContactUsData } from '../../redux/slices/contactusSlice'
import { useDispatch } from 'react-redux'
// import '../../assets/js/myscript'

const Layout = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const routeName = location?.pathname?.toLowerCase() || ""
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loader();
        bindContactUsData();
    }, [])

    const bindContactUsData = () => {
        dispatch(fecthContactUsData())
    }

    const loader = async () => {
        // Simulate a network request
        await new Promise(resolve => setTimeout(resolve, 1200));
        setLoading(false);
    };

    return (
        <>
            {loading &&
                <div id="pageloader">
                    <div className="loader-item"><img src="/assets/images/tail-spin.svg" width={80} alt /></div>
                </div>
            }
            <div className={`bg-primary header-bg marketing-home ${(routeName === "/onus/splash" || routeName === "/") ? '' : 'otherheaderbg'}`}>
                <HeaderNav />
                {(routeName === "/onus/splash" || routeName === "/") &&
                    <>
                        <b className="screen-overlay"></b>
                        <Banner />
                    </>
                }
                {!(routeName.includes("splash")) &&
                    NavBardata.map(element =>
                        routeName === element.pathUrl.toLowerCase() ?
                            <PageHeader headername={element.title} /> : null
                    )
                }
                {routeName.includes("jobpostdetail") &&
                    routeName === "/onus/jobpostdetail" ? <PageHeader headername="Job Seeker" /> : null
                }
            </div>
            <main id="body-content">
                <Outlet />
            </main>
            <Footer />

            {/* <div className={`mod-bg-1  mod-nav-link desktop chrome webkit pace-done nav-function-top blur ${sidebar}`}>
                <div className="App">
                    <div className="page-wrapper">
                        <div className="page-inner">
                            <HeaderBottom SetSidebarClass={SetSidebarClass} />
                            <div className="page-content-wrapper">
                                <HeaderTop sidebar={sidebar} SetSidebarClass={SetSidebarClass} />
                                <main id="js-page-content" role="main" className="page-content">
                                    <Outlet />
                                </main>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Layout