import React, { useEffect } from 'react'
import HomeCallUsNow from '../HomeCallUsNow'
import HomeCurrentOpening from '../HomeCurrentOpening'
import HomeAboutUs from '../HomeAboutUs'
import { useDispatch } from 'react-redux'
import { fetchSplashData } from '../../redux/slices/splashSlice'
import HomeServices from '../HomeServices'
import HomeTestimonial from '../HomeTestimonial'

export default function Splash() {
    const dispatch = useDispatch()

    useEffect(() => {
        bindHomeData();
    }, [])

    const bindHomeData = () => {
        dispatch(fetchSplashData())
    }

    return (
        <div>
            {/* <HomeCallUsNow /> */}
            <HomeAboutUs />
            <HomeCurrentOpening />
            <HomeServices />
            <HomeTestimonial />
        </div>
    )
}
