import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import './App.css';
import { mainRouteInitial } from './constants';
import { Provider } from "react-redux";
import Store from "./redux/store/Store";

import Layout from './component/Layout';
import Splash from './component/Splash';
import AboutUsPage from "./component/AboutUsPage";
import OurService from "./component/OurService";
import JobPostPage from "./component/JobPostPage";
import JobPostDetail from "./component/JobPostDetail";
import ContactUs from "./component/ContactUs";


const router = createBrowserRouter([
  {
    path: `/${mainRouteInitial}/`,
    element: <Layout />,
    children: [
      {
        path: "Splash",
        element: <Splash />
      },
      {
        path: "AboutUs",
        element: <AboutUsPage />
      },
      {
        path: "Services",
        element: <OurService />
      },
      {
        path: "JobSeeker",
        element: <JobPostPage />
      },
      {
        path: "JobPostDetail",
        element: <JobPostDetail />
      },
      {
        path: "ContactUs",
        element: <ContactUs />
      }
    ]
  },
  {
    path: `/`,
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Splash />
      }
    ]
  }
]);

function App() {
  return (
    <Provider store={Store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
