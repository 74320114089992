import React from 'react'

export default function PageHeader({ headername }) {

// console.log("hsdhshdshdshdh", headername);


    return (
        <section className="breadcrumbs-page">
            <div className="container">
                <h1>{headername}</h1>
                {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="javascript:"><i className="bi-house" /></a></li>
                        <li className="breadcrumb-item">Pages</li>
                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                    </ol>
                </nav> */}
            </div>
        </section>
    )
}