import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { JobPost } from '../../Process';
import DOMPurify from "dompurify"
import ApplyJobModel from '../../commoncomponent/ApplyJobModel';
import { handleSetId } from '../../redux/slices/jobPostSlice';
import { useNavigate } from 'react-router-dom';

export default function HomeCurrentOpening() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { tableData } = useSelector((state) => state.splashSlice)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getJobPostID, setJobPostID] = useState('');

    const openModal = (tempJobPostID) => {
        setIsModalOpen(true);
        setJobPostID(tempJobPostID)
    }
    const closeModal = () => setIsModalOpen(false);

    const bindSubDetail = (JobPostID) => {
        dispatch(handleSetId(JobPostID));
        navigate(`/Onus/JobPostDetail`);
    };

    return (
        <section className="our-service-pattern section-padding pt-0">
            <div className="container-fluid">
                <div className="row pos-rel">
                    <div className="col-xl-6 col-lg-10 mx-auto">
                        <div className="section-title text-center splitting animated">
                            {/* <span>What We Do</span> */}
                            <h2 className="wow word">Current <strong>Opening</strong></h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In urna lectus, mattis non
                            accumsan in, tempor dictum neque. In hac habitasse platea dictumst. Lorem ipsum dolor
                            sit amet, consectetur adipiscing.</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    {tableData?.[0]?.JobPostDetail?.map((element, index) => (
                        <>
                            <div className="col-lg-4 col-sm-6 mb-0">
                                <div className="icon-style-top-icon currentopening">
                                    {/* <div className="icon"><i className="srn-icon-digital-marketing" /></div> */}
                                    <div className='company-logo'>
                                        {/* <div className='logo'>
                                            <img src={JobPost + element?.Image} alt />
                                        </div>*/}
                                        <div className="company-details">
                                            <div className="name-location">
                                                <h5>
                                                    <a href="javascript:void(0)">{element?.Title}</a>
                                                </h5>
                                                <p>{element?.JobType}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12 jobpostdesc'>
                                        <p className='text-justify ellipsiscss'>
                                            <div dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    element?.Description
                                                ),
                                            }} style={{ marginBottom: '1px' }}>
                                            </div>
                                        </p>
                                    </div>
                                    <div className='col-md-12'>
                                        <ul className='list-unstyled icons-listing-bordered theme-primary mt-3 pt-3'>
                                            {element?.Experience &&
                                                <li><span className='font-bold'>Experience:</span>&nbsp;{element?.Experience}</li>
                                            }
                                            {element?.Location &&
                                                <li><span className='font-bold'>Location:</span>&nbsp;{element?.Location}</li>
                                            }
                                        </ul>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-6 text-left'>
                                            <button type="button" className="btn btn-default applynow" onClick={() => bindSubDetail(element?.JobPostID)}><span className="outer-wrap"><span data-text="Read More">Read More</span></span></button>
                                        </div>
                                        <div className='col-md-6 text-right'>
                                            <button type="button" className="btn btn-default applynow" onClick={() => openModal(element?.JobPostID)}><span className="outer-wrap"><span data-text="Apply Now">Apply Now</span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ApplyJobModel isOpen={isModalOpen} onClose={closeModal} JobPostID={(getJobPostID)} ApplyType={'FromJobPost'} />
                        </>
                    ))}
                </div>
            </div>
        </section>
    )
}
