import React from "react";

const PaginationButton = ({ handlePreviousPage, handleNextPage }) => {
    return (
        <div className="row">
            <div className="col-md-6 mobile50width">
                <button type="button" onClick={handlePreviousPage} className="btn btn-primary" style={{ margin: 5 }}>
                    <span className="outer-wrap"><span data-text="Prev">Prev</span></span>
                </button>
            </div>
            <div className="col-md-6 mobile50width">
                <button type="button" onClick={handleNextPage} className="btn btn-primary float-right" style={{ margin: 5 }}>
                    <span className="outer-wrap"><span data-text="Next">Next</span></span>
                </button>
            </div>
        </div>
    );
};

export default PaginationButton;
