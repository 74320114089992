import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../Process';
import { IntialPageCount } from '../../constants';

export const fetchJobPostData = createAsyncThunk(
    "data/fetchJobPostData",
    async ({ pageID, pageCount, uniqueID, IsPagination, jobpostid }) => {
        try {
            const response = await fetch(`${API_URL}JobPost/getJobPost`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    IsActive: "true",
                    IsDelete: "false",
                    JobPostID: jobpostid || null,
                    IsPagination: IsPagination,
                    PerPage: pageCount,
                    UniqueID: uniqueID
                })
            });
            const res = await response.json();

            if (res.status == true) {
                return {
                    pageID: pageID,
                    indexID: uniqueID,
                    data: res?.data,
                    totalCount: res?.totalCount
                };
            } else {
                return
            }
        } catch (err) {
            console.log(err)
        }
    }
);

const JobPostSlice = createSlice({
    name: 'JobPost',
    initialState: {
        tableData: [],
        paginationTableData: [],
        idValidDateFilter: false,
        totalCount: "0",
        pageID: 1,
        pageCount: IntialPageCount,
        indexID: "",
        isEmpty: false,
        loading: true,
        loading2: true,
        error: null,
        jobpostid: null
        // filterData: {

        // }
    },
    reducers: {
        handleSetData: (state, action) => {
            state.tableData = action.payload
            state.pageID = state.pageID + 1
        },
        setidValidDateFilter: (state, action) => {
            state.idValidDateFilter = action.payload;
        },
        handlePrevious: (state, action) => {
            console.log(action.payload.data, action.payload.pageID, "action.payload")
            if (action.payload.pageID == 0) {
                return
            }
            state.tableData = action.payload.data[action.payload.pageID - 1]
            state.pageID = state.pageID - 1
        },
        handleSetId: (state, action) => {
            state.jobpostid = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobPostData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchJobPostData.fulfilled, (state, action) => {
                if (!action.payload) {
                    return
                }
                state.loading = false;
                // console.log(action?.payload.data, "action?.payload.data")
                state.totalCount = action?.payload.totalCount
                if (!action?.payload.data?.length) {
                    state.isEmpty = true;
                    return
                } else {
                    state.isEmpty = false;
                }
                // console.log(action?.payload, "action?.payload.count_data?.count")
                state.pageID = action.payload.pageID
                state.tableData = action.payload.data;
                state.paginationTableData.push(
                    action.payload.data
                )
                state.indexID = action.payload.data[action.payload.data?.length - 1]?.JobPostID || ""
            })
            .addCase(fetchJobPostData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export const { handleSetData, setidValidDateFilter, handlePrevious, handleSetId } = JobPostSlice.actions;

export default JobPostSlice.reducer;