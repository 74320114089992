export const EnterName = "Please Enter Name"
export const EnterMobileNo = "Please Enter Mobile No"
export const EnterEmailID = "Please Enter Email ID"
export const EnterGender = "Please Enter Gender"
export const EnterCurrentLocation = "Please Enter Current Location"
export const EnterApplyType = "Please Enter Apply Type"
export const EnterApplyFor = "Please Enter Apply For"
export const EnterExperience = "Please Enter Experience"
export const EnterJobDescription = "Please Enter Job Description"
export const EnterResume = "Please Upload Resume"
 
export const EnterProperMobileNo = "Please Enter Valid Mobile No"
export const EnterProperEmailID = "Please Enter Valid Email ID"
export const EnterValidFile = "Please Enter Valid File"

