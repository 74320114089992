import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Testimonial } from '../../Process';
import DOMPurify from "dompurify"

export default function HomeTestimonial() {
    const { tableData } = useSelector((state) => state.splashSlice)

    return (
        <>
            {
                tableData?.[0]?.TestimonialDetail &&
                <>
                    <section className="section-padding bg-default line-bg testimonialcss">
                        <div className="line-1"><svg xmlns="http://www.w3.org/2000/svg" width="1570.917" height="656.371" viewBox="0 0 1570.917 656.371">
                            <path id="ling_abstract" d="M608,101s126-116,320,7,362-21,405,223,126,46,226,255,619,81,619,81" transform="translate(-607.323 -50.436)" fill="none" stroke strokeLinejoin="round" strokeWidth={2} strokeDasharray={10} />
                        </svg></div>
                        <div className="line-2"><svg xmlns="http://www.w3.org/2000/svg" width="1570.917" height="656.371" viewBox="0 0 1570.917 656.371">
                            <path id="ling_abstract1" d="M608,101s126-116,320,7,362-21,405,223,126,46,226,255,619,81,619,81" transform="translate(-607.323 -50.436)" fill="none" stroke strokeLinejoin="round" strokeWidth={2} strokeDasharray={10} />
                        </svg></div>
                        <div className="line-3"><svg xmlns="http://www.w3.org/2000/svg" width="1570.917" height="656.371" viewBox="0 0 1570.917 656.371">
                            <path id="ling_abstract2" d="M608,101s126-116,320,7,362-21,405,223,126,46,226,255,619,81,619,81" transform="translate(-607.323 -50.436)" fill="none" stroke strokeLinejoin="round" strokeWidth={2} strokeDasharray={10} />
                        </svg></div>
                        <div className="line-4"><svg xmlns="http://www.w3.org/2000/svg" width="1570.917" height="656.371" viewBox="0 0 1570.917 656.371">
                            <path id="ling_abstract3" d="M608,101s126-116,320,7,362-21,405,223,126,46,226,255,619,81,619,81" transform="translate(-607.323 -50.436)" fill="none" stroke strokeLinejoin="round" strokeWidth={2} strokeDasharray={10} />
                        </svg></div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-xl-5 mx-auto text-center">
                                    <div className="section-title text-center light"><span>Testimonial</span>
                                        <h2 className="wow">Trusted User <strong>Reviews</strong></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                {/* <div className="col-xl-5 col-lg-6 text-center">
                                    <div className="circle-thumbs">
                                        <img src="/assets/images/thumb_1.jpg" alt className="thumb one" />
                                        <img src="/assets/images/thumb_2.jpg" alt className="thumb two" />
                                        <img src="/assets/images/thumb_3.jpg" alt className="thumb three" />
                                        <img src="/assets/images/thumb_4.jpg" alt className="thumb four" />
                                        <img src="/assets/images/thumb_5.jpg" alt className="thumb five" />
                                        <img src="/assets/images/thumb_6.jpg" alt className="thumb six" />
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1168 1168">
                                            <g fill="none" stroke="#000" strokeDasharray={5} strokeWidth={2}>
                                                <g data-name="Ellipse 1">
                                                    <circle cx={584} cy={584} r={584} stroke="none" />
                                                    <circle cx={584} cy={584} r={583} />
                                                </g>
                                                <g data-name="Ellipse 2" transform="translate(154 154)">
                                                    <circle cx={430} cy={430} r={430} stroke="none" />
                                                    <circle cx={430} cy={430} r={429} />
                                                </g>
                                                <g data-name="Ellipse 3" transform="translate(310 310)">
                                                    <circle cx={274} cy={274} r={274} stroke="none" />
                                                    <circle cx={274} cy={274} r={273} />
                                                </g>
                                            </g>
                                        </svg></div>
                                </div> */}
                                {/* <div className="col-xl-6 col-lg-6 offset-xl-1"> */}
                                <div className="col-md-12">
                                    <div className="testimonials-slider-single-wrap">
                                        <div className="svg-wrap"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path d="M437 75a254 254 0 0 0-362 0 254 254 0 0 0-39 312L1 492a15 15 0 0 0 19 19l105-35a255 255 0 0 0 387-220c0-68-27-133-75-181zM256 482c-43 0-84-12-121-35a15 15 0 0 0-12-2l-84 28 28-84c1-4 1-9-2-12A225 225 0 0 1 256 30a226 226 0 0 1 0 452z" />
                                            <path d="M346 161h-40c-19 0-35 16-35 35v40c0 19 16 35 35 35h43c-7 29-32 50-63 50a15 15 0 0 0 0 30c52 0 95-43 95-95v-60c0-19-16-35-35-35zm-45 75v-40c0-3 2-5 5-5h40c3 0 5 2 5 5v45h-45c-3 0-5-2-5-5zm-95-75h-40c-19 0-35 16-35 35v40c0 19 16 35 35 35h43c-7 29-32 50-63 50a15 15 0 0 0 0 30c52 0 95-43 95-95v-60c0-19-16-35-35-35zm-45 75v-40c0-3 2-5 5-5h40c3 0 5 2 5 5v45h-45c-3 0-5-2-5-5z" />
                                        </svg></div>
                                        {/* <div className="owl-carousel owl-theme dots-border-light" id="testimonials-slider-single"> */}
                                        <OwlCarousel id="testimonials-slider-single" className='owl-carousel owl-theme dots-border-light' loop margin={10} nav autoplay items={1}>
                                            {tableData?.[0]?.TestimonialDetail?.map((element, index) => (
                                                <div className="item">
                                                    <div className="testimonial-single-left">
                                                        {/* <hr className="divider-secondary" /> */}
                                                        {/* <h6>My business is growing faster and I’m very happy with that</h6> */}
                                                        <p>
                                                            <div className='text-justify' dangerouslySetInnerHTML={{
                                                                __html: DOMPurify.sanitize(
                                                                    element?.Description
                                                                ),
                                                            }} style={{ marginBottom: '1px' }}>
                                                            </div>
                                                        </p>
                                                        {/* <p className='text-justify'>{element?.Description}</p> */}
                                                        <div className="testimonial-box">
                                                            <div className="thumb-img">
                                                                <img className='testimonialimg' src={Testimonial + element?.Image} alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="name">{element?.Title}</h6>
                                                                {/* <div className="post">Marketing Envato Pty Ltd.</div>
                                                                <div className="rating"><i className="bi bi-star-fill" /> <i className="bi bi-star-fill" /> <i className="bi bi-star-fill" /> <i className="bi bi-star-fill" /> <i className="bi bi-star" /></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
        </>
    )
}
