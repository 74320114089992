import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_URL } from '../../Process';

export const fetchAboutUsData = createAsyncThunk(
    "data/fetchAboutUsData",
    async () => {
        try {
            const response = await fetch(`${API_URL}AboutUs/getAboutUs`, {
                method: "post",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "IsActive": "true",
                    "IsDelete": "false"
                })
            });
            const res = await response.json();

            if (res.status == true) {
                return {
                    data: res?.data
                };
            } else {
                return
            }
        } catch (err) {
            console.log(err)
        }
    }
);

const AboutUsSlice = createSlice({
    name: 'AboutUs',
    initialState: {
        tableData: [],
        totalCount: "0",
        indexID: "",
        isEmpty: false,
        loading: true,
        loading2: true,
        error: null,
        // filterData: {

        // }
    },
    reducers: {
        handleSetData: (state, action) => {
            state.tableData = action.payload
            state.pageID = state.pageID + 1
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAboutUsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAboutUsData.fulfilled, (state, action) => {
                if (!action.payload) {
                    return
                }
                state.loading = false;
                // console.log(action?.payload.data, "action?.payload.data")
                state.totalCount = action?.payload.totalCount
                // if (!action?.payload.data?.length) {
                //     state.isEmpty = true;
                //     return
                // } else {
                //     state.isEmpty = false;
                // }
                // console.log(action?.payload, "action?.payload.count_data?.count")
                // state.pageID = action.payload.pageID
                state.tableData = action.payload.data;
                // state.paginationTableData.push(
                //     action.payload.data
                // )
                // state.indexID = action.payload.data[action.payload.data?.length - 1]?.crime_details_id || ""
            })
            .addCase(fetchAboutUsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
    },
});

export const { handleSetData } = AboutUsSlice.actions;

export default AboutUsSlice.reducer;