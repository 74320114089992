import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobPostData, handlePrevious, handleSetData, handleSetId } from '../../redux/slices/jobPostSlice';
import { JobPost } from '../../Process';
import DOMPurify from "dompurify"
import PaginationButton from '../../commoncomponent/PaginationButton';
import { useNavigate } from 'react-router-dom';
import ApplyJobModel from '../../commoncomponent/ApplyJobModel';
import { IntialPageCount } from '../../constants';

export default function JobPostPage() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { idValidDateFilter, tableData, pageID, pageCount, indexID, paginationTableData, totalCount } = useSelector((state) => state.jobPostSlice)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getJobPostID, setJobPostID] = useState('');

    useEffect(() => {
        bindJobPostData();
    }, [])

    const openModal = (tempJobPostID) => {
        setIsModalOpen(true);
        setJobPostID(tempJobPostID)
    }
    const closeModal = () => setIsModalOpen(false);

    const bindJobPostData = () => {
        dispatch(fetchJobPostData({ pageID: 1, pageCount, uniqueID: "", IsPagination: "true" }))
    }

    const handleNextPage = async () => {
        if (idValidDateFilter) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional smooth scrolling behavior
            });
            return;
        }
        if (pageID < paginationTableData.length) {
            return dispatch(handleSetData(paginationTableData[pageID]))
        }
        dispatch(fetchJobPostData({ pageID: pageID + 1, pageCount, uniqueID: indexID, IsPagination: "true" }))
    }

    const handlePreviousPage = () => {
        if (idValidDateFilter) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });;
            return;
        }
        dispatch(handlePrevious({ pageID: pageID - 1, data: paginationTableData }))
    }

    const bindSubDetail = (JobPostID) => {
        dispatch(handleSetId(JobPostID));
        navigate(`/Onus/JobPostDetail`);
    };

    return (
        <>
            <section className="our-service-pattern section-padding ourservice">
                <div className="container-fluid">
                    {/* <div className="row pos-rel">
                    <div className="col-xl-6 col-lg-10 mx-auto">
                        <div className="section-title text-center splitting animated">
                            <h2 className="wow word">Current <strong>Opening</strong></h2>
                        </div>
                    </div>
                </div> */}
                    <div className="row">
                        {tableData?.map((element, index) => (
                            <div className="col-lg-4 col-sm-6 mb-0">
                                <div className="icon-style-top-icon currentopening">
                                    {/* <div className="icon"><i className="srn-icon-digital-marketing" /></div> */}
                                    <div className='company-logo'>
                                        {/* <div className='logo'>
                                            <img src={JobPost + element?.Image} alt />
                                        </div> */}
                                        <div className="company-details">
                                            <div className="name-location">
                                                <h5>
                                                    <a href="/job-details">{element?.Title}</a>
                                                </h5>
                                                <p>{element?.JobType}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='col-md-12 jobpostdesc'>
                                            <p className='text-justify ellipsiscss'>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        element?.Description
                                                    ),
                                                }} style={{ marginBottom: '1px' }}>
                                                </div>
                                            </p>
                                        </div>
                                        <div className='col-md-12'>
                                            <ul className='list-unstyled icons-listing-bordered theme-primary mt-3 pt-3'>
                                                {element?.Experience &&
                                                    <li><span className='font-bold'>Experience:</span>&nbsp;{element?.Experience}</li>
                                                }
                                                {element?.Location &&
                                                    <li><span className='font-bold'>Location:</span>&nbsp;{element?.Location}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 text-left'>
                                            <button type="button" className="btn btn-outline-secondary applynow" onClick={() => bindSubDetail(element?.JobPostID)}><span className="outer-wrap"><span data-text="Read More">Read More</span></span></button>
                                        </div>
                                        <div className='col-md-6 text-right'>
                                            <button type="button" className="btn btn-outline-secondary applynow"
                                                onClick={() => openModal(element?.JobPostID)}><span className="outer-wrap"><span data-text="Apply Now"
                                                // onClick={openModal(element?.JobPostID)}
                                                >Apply Now</span></span></button>
                                        </div>
                                    </div>
                                    <ApplyJobModel isOpen={isModalOpen} onClose={closeModal} JobPostID={(getJobPostID)} ApplyType={'FromJobPost'} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {(totalCount > IntialPageCount) &&
                    <div className="container-fluid">
                        <PaginationButton handlePreviousPage={handlePreviousPage} handleNextPage={handleNextPage} />
                    </div>
                }
            </section>
        </>
    )
}
