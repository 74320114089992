import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ApplyJobModel from '../../commoncomponent/ApplyJobModel';
import { mainRouteInitial } from '../../constants';
import { NavLink } from 'react-router-dom'

export default function Footer() {
    const { tableData } = useSelector((state) => state.contactusSlice)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getApplyType, setApplyType] = useState('');

    const openModal = (tempApplyType) => {
        setIsModalOpen(true);
        setApplyType(tempApplyType)
    }
    const closeModal = () => setIsModalOpen(false);

    console.log("sfsdfsfsdf", getApplyType);
    

    return (
        <>
            <div>
                <div className="footer-wrap">
                    {/* <div className="container">
                    <div className="footer-subscribe">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4">
                                <div className="social-icons">
                                    <ul className="list-unstyled">
                                        <li><a href="javascript:"><i className="bi bi-facebook" /></a></li>
                                        <li><a href="javascript:"><i className="bi bi-twitter-x" /></a></li>
                                        <li><a href="javascript:"><i className="bi bi-instagram" /></a></li>
                                        <li><a href="javascript:"><i className="bi bi-linkedin" /></a></li>
                                        <li><a href="javascript:"><i className="bi bi-youtube" /></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div className="align-items-center row form-inputs">
                                    <div className="col-md-6 col-lg-7 text-lg-end">
                                        <h6>Subscribe Our Newsletter</h6>Best for one-man bands, web creators, and freelancers.
                                    </div>
                                    <div className="col-md-6 col-lg-5">
                                        <div className="d-flex"><input type="text" name="subscribe" placeholder="Enter your email address" className="form-control bordered" /> <button type="submit" className="btn btn-secondary bordered"><i className="bi bi-send" /></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                    <footer>
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-xxl-7 col-lg-6">
                                    <div className="row">
                                        <div className="col-xxl-5 col-md-6">
                                            <div className="footer-widget">
                                                <div className="logo-footer">
                                                    {/* <a href="index.html"><img src="assets/images/logo_dark.svg" alt /></a> */}
                                                    <a className="navbar-brand logobgcss six" href="javascript:void(0)">
                                                        <img src="/assets/image/logo1.png" className='logocss' alt />
                                                        <span className='headecss'>ONUS<span></span></span>
                                                    </a>
                                                </div>
                                                <div className="footer-widget-contact">
                                                    <ul className="list-unstyled">
                                                        {tableData?.[0]?.Address &&
                                                            <li>
                                                                <div><i className="bi bi-geo-alt" /></div>
                                                                <div>{tableData?.[0]?.Address}</div>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.MobileNo1 &&
                                                            <li>
                                                                <div><i className="bi bi-phone" /></div>
                                                                <div>
                                                                    <a href={`tel:${tableData?.[0]?.MobileNo1}`}>+91 {tableData?.[0]?.MobileNo1}</a>
                                                                </div>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.EmailID1 &&
                                                            <li>
                                                                <div><i className="bi bi-envelope" /></div>
                                                                <div><a href="javascript:void(0)"><span className="__cf_email__">{tableData?.[0]?.EmailID1}</span></a>
                                                                </div>
                                                            </li>
                                                        }
                                                        {tableData?.[0]?.LinkedinLink &&
                                                            <li>
                                                                <div><i className="bi bi-linkedin" /></div>
                                                                <div><a target='_blank' href={tableData?.[0]?.LinkedinLink}>Linkedin</a>
                                                                </div>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xxl-5 col-md-6 ms-auto">
                                            <div className="footer-widget">
                                                <h4 className="widget-title">Apply for Job</h4>
                                                <p className="footer-text">Get Best Matched Jobs. Add Resume Now!</p>
                                                <div className="opening-hours">
                                                    <div className="text">
                                                        <button type="button" className="btn btn-secondary" onClick={() => openModal('FromQuery')}><span className="outer-wrap"><span data-text="Upload Resume">Upload Resume</span></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-5 col-lg-6 mr-top-footer">
                                    <div className="row">
                                        <div className="col-md-4 col-12">
                                            <div className="footer-widget">
                                                <h4 className="widget-title">Sitemap</h4>
                                                <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                    <li>
                                                        <NavLink to={`/${mainRouteInitial}/AboutUs`}>About Us
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`/${mainRouteInitial}/ContactUs`}>Contact Us
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <div className="footer-widget">
                                                <h4 className="widget-title wtmobile">&nbsp;</h4>
                                                <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                    <li>
                                                        <NavLink to={`/${mainRouteInitial}/Services`}>Services
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to={`/${mainRouteInitial}/JobSeeker`}>Job Seeker
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4 col-12">
                                            <div className="footer-widget">
                                                <h4 className="widget-title">Services</h4>
                                                <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                    <li><a href="javascript:">Pricing</a></li>
                                                    <li><a href="javascript:">Support</a></li>
                                                    <li><a href="javascript:">Sales and Refunds</a></li>
                                                    <li><a href="javascript:">Legal</a></li>
                                                    <li><a href="javascript:">Testimonials &amp; Faq’s</a></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="tiny-footer">
                                <div className="row align-items-center text-center">
                                    <div className="col-md-12 mb-0 footerfont">{new Date().getFullYear()} © <span id="yearText" />
                                        <strong><a className='footera' target='_blank' href='https://smtechno.com/'>S M Techno Consultants Pvt. Ltd.</a></strong> All rights reserved.</div>
                                    {/* <div className="col-md-6">
                                        <div className="tiny-footer-links">
                                            <ul className="list-unstyled list-inline">
                                                <li><a href="#">Terms of Use</a></li>
                                                <li><a href="#">Privacy Policy</a></li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </footer>
                </div><a id="back-to-top" href="javascript:" className="back-to-top"><i className="bi bi-chevron-up" /></a>
                <div className="overlay overlay-hugeinc">
                    <form className="form-inline mt-2 mt-md-0">
                        <div className="form-inner">
                            <div className="form-inner-div hstack"><i className="srn-search" />
                                <div className="w-100"><input className="form-control form-light" type="text" placeholder="Search" aria-label="Search" /></div><a href="#" className="overlay-close link-oragne"><i className="bi bi-x" /></a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ApplyJobModel isOpen={isModalOpen} onClose={closeModal} JobPostID={(null)} ApplyType={getApplyType} />
        </>
    )
}
