export const NavBardata = [
    {
        titleText: "Home",
        title: "Home",
        pathUrl: "/Onus/Splash",
        // iconClass: "fal fa-chart-pie",
    },
    {
        titleText: "About Us",
        title: "About Us",
        pathUrl: "/Onus/AboutUs",
        // iconClass: "fal fa-chart-pie",
    },
    {
        titleText: "Services",
        title: "Services",
        pathUrl: "/Onus/Services",
        // iconClass: "fal fa-chart-pie",
    },
    {
        titleText: "Job Seeker",
        title: "Job Seeker",
        pathUrl: "/Onus/JobSeeker",
        // iconClass: "fal fa-chart-pie",
    },
    {
        titleText: "Contact Us",
        title: "Contact Us",
        pathUrl: "/Onus/ContactUs",
        // iconClass: "fal fa-chart-pie",
    }
]