import React from 'react'
import { useSelector } from 'react-redux'

export default function HomeCallUsNow() {
    const { tableData } = useSelector((state) => state.splashSlice)

    return (
        <section className="section-spacing mobilecallus">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 pos-rel mb-10">
                        {tableData?.[0]?.ContactDetail &&
                            <div className="banner-call">
                                <div className="icon"><i className="bi bi-telephone" /></div>
                                <div className="content">
                                    <div className="head">Call Us Now</div>
                                    <a href={`tel:${tableData?.[0]?.ContactDetail?.[0]?.MobileNo1}`}>
                                        +91 {tableData?.[0]?.ContactDetail?.[0]?.MobileNo1}
                                    </a>
                                </div>
                            </div>
                        }
                        {/* <div className="owl-carousel owl-theme top-right-arrow" id="marketing-banner-slider">
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client1.png" alt /></div>
                                </div>
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client2.png" alt /></div>
                                </div>
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client3.png" alt /></div>
                                </div>
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client4.png" alt /></div>
                                </div>
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client5.png" alt /></div>
                                </div>
                                <div className="item">
                                    <div className="img-partner"><img src="assets/images/partner/img-client6.png" alt /></div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
