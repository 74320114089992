import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurServiceData, handlePrevious, handleSetData } from '../../redux/slices/ourservicesSlice';
import { Service } from '../../Process';
import ShowMoreText from 'react-show-more-text';
import PaginationButton from '../../commoncomponent/PaginationButton';
import { IntialPageCount } from '../../constants';
import DOMPurify from "dompurify"

export default function OurService() {
    const dispatch = useDispatch()
    const { idValidDateFilter, tableData, pageID, pageCount, indexID, paginationTableData, totalCount } = useSelector((state) => state.ourservicesSlice)

    useEffect(() => {
        bindOurServiceData();
    }, [])

    const bindOurServiceData = () => {
        dispatch(fetchOurServiceData({ pageID: 1, pageCount, uniqueID: "", IsPagination: "true" }))
    }

    const executeOnClick = (isExpanded) => {
        // console.log(isExpanded);
    }

    const handleNextPage = async () => {
        if (idValidDateFilter) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional smooth scrolling behavior
            });
            return;
        }
        if (pageID < paginationTableData.length) {
            return dispatch(handleSetData(paginationTableData[pageID]))
        }
        dispatch(fetchOurServiceData({ pageID: pageID + 1, pageCount, uniqueID: indexID, IsPagination: "true" }))
    }

    const handlePreviousPage = () => {
        if (idValidDateFilter) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });;
            return;
        }
        dispatch(handlePrevious({ pageID: pageID - 1, data: paginationTableData }))
    }

    return (
        <>
            {
                tableData &&
                <div>
                    <section className="section-spacing pt-0 section-padding">
                        <div className="container-fluid">
                            <div className="row isotope-gallery">
                                {tableData?.map((element, index) => (
                                    <div className="col-sm-6 col-lg-4 gallery-item">
                                        <div className="blog-wrap-rounded">
                                            {/* <div className="img-wrap">
                                                <a href="javascript:void(0)">
                                                    <img src={Service + (element?.Image || 'ourservice.webp')} alt />
                                                    <div className="img-over"><i className="bi bi-plus-lg" /></div>
                                                </a >
                                            </div> */}
                                            <div className="blog-content">
                                                <div className="post-footer">
                                                    <div className="post-author">
                                                        <div className="author-text">
                                                            <h6><a href="javascript:">{element?.Title}</a></h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ShowMoreText
                                                    lines={7}
                                                    more="Show more"
                                                    less="Show less"
                                                    className="content-css"
                                                    anchorClass="show-more-less-clickable"
                                                    onClick={executeOnClick}
                                                    expanded={false}
                                                    // width={'100%'}
                                                    truncatedEndingComponent={"... "}
                                                >
                                                    {/* <p className='text-justify'>{element?.Description}</p> */}

                                                    <p className='text-justify'>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: DOMPurify.sanitize(
                                                                element?.Description
                                                            ),
                                                        }} style={{ marginBottom: '1px' }}>
                                                        </div>
                                                    </p>
                                                </ShowMoreText>
                                            </div>
                                        </div >
                                    </div >
                                ))}
                            </div>
                        </div>
                    </section>

                    {(totalCount > IntialPageCount) &&
                        <section className="section-spacing">
                            <div className="container-fluid">
                                <PaginationButton handlePreviousPage={handlePreviousPage} handleNextPage={handleNextPage} />
                            </div>
                        </section>
                    }
                </div>
            }
        </>
    )
}