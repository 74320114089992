import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { NavBardata } from '../../data/NavBardata'
import { useSelector } from 'react-redux'

export default function HeaderNav() {
    const { tableData } = useSelector((state) => state.contactusSlice)
    const [hasClass, setHasClass] = useState(false);

    const location = useLocation()
    const routeName = location?.pathname?.toLowerCase() || ""

    useEffect(() => {
        // debugger;

        console.log("dadadadadad", hasClass);
        if (hasClass) {
            document.addEventListener('click', handleAddClick);
        } else {
            document.addEventListener('click', handleRemoveClick);
        }
        return () => {
            document.addEventListener('click', handleRemoveClick);
        };
    }, [hasClass]);

    const handleRemoveClick = () => {
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.classList.remove('show');
        }
    };

    const handleAddClick = () => {
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
        if (offcanvasBackdrop) {
            offcanvasBackdrop.classList.add('show');
        }
    };

    const mangeMobileMenu = (hasClass) => {
        console.log(hasClass,"hasClasshasClass")
        // const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
        // if (offcanvasBackdrop) {
        //     offcanvasBackdrop.classList.remove('show');
        // } else {
        //     offcanvasBackdrop.classList.add('show');
        // }
        // if(hasClass){
            setHasClass(hasClass);
        // }
        
    };

    // const mangeMobileMenu = () => {
    //     setHasClass(!hasClass);
    //     const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
    //     if (offcanvasBackdrop) {
    //         // offcanvasBackdrop.classList.remove('show');
    //         document.body.style = 'overflow: visible;';
    //         offcanvasBackdrop.remove();
    //     }
    //     // else {
    //     //     offcanvasBackdrop.classList.add('show');
    //     // }

    // };

    return (
        <header>
            <div className="container">
                <div className="top-bar">
                    <div className="row">
                        <div className="col-md-6 order-md-last text-right">
                            {/* <div className="language-links">
                                <div>
                                    <a href="#"><i className='bi bi-phone'></i></a>
                                </div>
                                <div>
                                    <a href="#"><i className='bi bi-phone'></i></a>
                                </div>
                            </div> */}
                            <div className="top-bar-links">
                                <p className='mb-0'><a href={`tel:${tableData?.[0]?.MobileNo1}`}>Contact: +91 {tableData?.[0]?.MobileNo1}</a></p>
                                {tableData?.[0]?.LinkedinLink && <p><a target='_blank' href={tableData?.[0]?.LinkedinLink}>Get In Touch: <i className='bi bi-linkedin'></i></a></p>}
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="top-bar-links"><a href="#">Products</a> <a href="#">Careers</a> <a href="#">Support</a> <a href="#">Login</a></div>
                        </div> */}
                        <div className='col-md-6 panellogo'>
                            <a className="navbar-brand logobgcss six" href="javascript:void(0)">
                                <img src="/assets/image/logo1.png" className='logocss' alt />
                                <span className='headecss'>ONUS<span></span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg header-anim">
                <div className="container">

                    <div className="navbar-brand mobilelogo">
                        <a className="navbar-brand logobgcss six" href="javascript:void(0)">
                            <img src="/assets/image/logo1.png" className='logocss' alt />
                            <span className='headecss'>ONUS<span></span></span>
                        </a>
                    </div>

                    <button
                        className="navbar-toggler x collapsed" type="button" onClick={() => mangeMobileMenu(true)}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>


                    <div className={`navbar-collapse offcanvas offcanvas-start offcanvas-collapse ${hasClass ? 'show' : ''}`} id="navbarCollapse">
                        {/* <div className="navbar-collapse offcanvas offcanvas-start offcanvas-collapse" id="navbarCollapse"> */}
                        <div className="offcanvas-header">
                            <a className="navbar-brand">
                                <div className="navbar-brand mobilelogo">
                                    <a className="navbar-brand logobgcss2 six" href="javascript:void(0)">
                                        <img src="/assets/image/logo1.png" className='logocss' alt />
                                        <span className='headecss'>ONUS<span></span></span>
                                    </a>
                                </div>
                            </a>
                            <button className="navbar-toggler x collapsed" onClick={() => mangeMobileMenu(false)} type="button" ><i className="bi bi-x-lg" /></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav">
                                {NavBardata.map(element => {
                                    return <li className={
                                        element.children ? (element.children.filter((item) => routeName == item.pathUrl.toLowerCase()).length ? 'nav-item active' : (routeName === '/' && element.title === 'Home') ? 'nav-item active' : 'nav-item') : (routeName == element.pathUrl.toLowerCase() ? 'nav-item active' : (routeName === '/' && element.title === 'Home') ? 'nav-item active' : 'nav-item')}>
                                        {/* <a className="nav-link" href="#" aria-expanded="false">Home</a> */}

                                        {/* onClick={() => mangeMobileMenu()} */}
                                        <NavLink className="nav-link" to={element.pathUrl} aria-expanded="false" onClick={() => mangeMobileMenu(false)}>{element.title}
                                        </NavLink>
                                    </li>
                                })
                                }


                                {/* <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">About</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="about-us.html">About Us</a></li>
                                        <li><a className="dropdown-item" href="our-team.html">Our Team</a></li>
                                        <li><a className="dropdown-item" href="team-single.html">Team Single</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="our-services.html">Our Services</a></li>
                                        <li><a className="dropdown-item" href="service-single.html">Services Single</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Pages</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="contact.html">Contact Us</a></li>
                                        <li><a className="dropdown-item" href="faqs.html">Faqs</a></li>
                                        <li><a className="dropdown-item" href="our-pricing.html">Our Pricing</a></li>
                                        <li><a className="dropdown-item" href="coming-soon.html">Coming soon</a></li>
                                        <li><a className="dropdown-item" href="404-page.html">404 Page</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Portfolio</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="portfolio-2col.html">Portfolio 2 Column</a>
                                        </li>
                                        <li><a className="dropdown-item" href="portfolio-3col.html">Portfolio 3 Column</a>
                                        </li>
                                        <li><a className="dropdown-item" href="portfolio-4col.html">Portfolio 4 Column</a>
                                        </li>
                                        <li><a className="dropdown-item" href="portfolio-single.html">Portfolio Single</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="blog-grid.html">Blog Grid</a></li>
                                        <li><a className="dropdown-item" href="blog-standard.html">Blog Standard</a></li>
                                        <li><a className="dropdown-item" href="blog-single.html">Blog Single</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Elements</a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="typography.html#heading">Headings Styles</a>
                                        </li>
                                        <li><a className="dropdown-item" href="typography.html#icons">Icons Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#button-style">Button
                                            Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#blockquotes-styles">Blockquotes Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#bullet-styles">Bullet
                                            Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#tabs-styles">Tabs Styles</a>
                                        </li>
                                        <li><a className="dropdown-item" href="typography.html#accordian-styles">Accordian
                                            Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#forms-styles">Forms
                                            Styles</a></li>
                                        <li><a className="dropdown-item" href="typography.html#pagination-styles">Pagination
                                            Styles</a></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
