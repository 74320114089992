import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutUsData } from '../../redux/slices/aboutusSlice';
import DOMPurify from "dompurify"
import { AboutUs } from '../../Process';

export default function AboutUsPage() {
    const dispatch = useDispatch()
    const { tableData } = useSelector((state) => state.aboutusSlice)

    useEffect(() => {
        bindAboutUsData();
    }, [])

    const bindAboutUsData = () => {
        dispatch(fetchAboutUsData())
    }

    return (
        <>
            {
                tableData &&
                <section className="section-padding">
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-0">
                                <div className="img-character">
                                    <img className='aboutusheight' src={AboutUs + tableData?.[0]?.Image} alt />
                                </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <div className="section-title text-start splitting animated">
                                    <h2 className="wow word">{tableData?.[0]?.Title} <strong></strong></h2>
                                    <p>
                                        <div className='text-justify' dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                tableData?.[0]?.Description
                                            ),
                                        }} style={{ marginBottom: '1px' }}>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="container">
                        {/* <div className="row"> */}
                        <div>
                      
                                <div className="mb-0 aboutusimgdiv">
                                    {/* <div className="img-gradient"> */}
                                    <div className="img-character">
                                        {/* <img src="assets/images/about_us_img.jpg" alt /> */}
                                        <img className='aboutusheight' src={AboutUs + tableData?.[0]?.Image} alt />
                                    </div>
                                    {/* <div className="years-started wow fadeInUp">
                                    <div className="years">10<sup>+</sup> <span>Years</span></div>
                                    <div className="content fun-fact">
                                        <h1><span className="timer" data-to={2010} data-speed={2000}>2010</span> <span>Making
                                            Business Since</span></h1>
                                    </div>
                                </div> */}
                                </div>
                                <div className="mt-4">
                                    <div className="section-title text-start splitting animated">
                                        {/* <span>What We Offer</span> */}
                                        <h2 className="wow word">{tableData?.[0]?.Title} <strong></strong></h2>
                                        <p>
                                            <div className='text-justify' dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    tableData?.[0]?.Description
                                                ),
                                            }} style={{ marginBottom: '1px' }}>
                                            </div>
                                        </p>
                                    </div>
                                </div>

                            

                        </div>
                    </div>
                </section>
            }
        </>
    )
}