import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchBannerData } from '../../redux/slices/bannerSlice';
import { BannerImg } from '../../Process';
import HomeCallUsNow from '../HomeCallUsNow';

export default function Banner() {
    const dispatch = useDispatch()
    const { tableData } = useSelector((state) => state.bannerSlice)

    useEffect(() => {
        bindBannerData();
    }, [])

    const bindBannerData = () => {
        dispatch(fetchBannerData())
    }

    return (
        <div className="marketing-banner-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="marketing-banner">
                            <div className="title">
                                {/* <span>For Marketing and Creative Teams</span> */}
                                {tableData?.[0]?.Title && <h4 className="wow"> <strong>{tableData?.[0]?.Title}</strong></h4>}
                                {tableData?.[0]?.Description && <p className="lead text-justify">{tableData?.[0]?.Description}</p>}
                                {/* <div className="cta-group"><a href="javascript:" className="btn btn-secondary" data-trigger="#signup"><span className="outer-wrap"><span data-text="Try for free">Try for free</span></span></a> <a href="contact.html" className="btn btn-default"><span className="outer-wrap"><span data-text="Request a demo">Request a demo</span></span></a></div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5 offset-lg-1"> */}
                    <div className="col-lg-4 offset-lg-2">
                        <div className="marketing-character">
                            {tableData?.[0]?.BannerImage ?
                                <img src={BannerImg + tableData?.[0]?.BannerImage} alt />
                                :
                                <img src="/assets/images/img-character-mac.png" alt />
                            }
                        </div>
                    </div>
                </div>
                <HomeCallUsNow />
            </div>
        </div>
    )
}
