import { configureStore } from "@reduxjs/toolkit";
import splashSlice from "../slices/splashSlice";
import aboutusSlice from "../slices/aboutusSlice";
import ourservicesSlice from "../slices/ourservicesSlice";
import jobPostSlice from "../slices/jobPostSlice";
import contactusSlice from "../slices/contactusSlice";
import bannerSlice from "../slices/bannerSlice";

const Store = configureStore({
    reducer: {
       splashSlice,
       aboutusSlice,
       ourservicesSlice,
       jobPostSlice,
       contactusSlice,
       bannerSlice
    }
})

export default Store