import { MultiSelect } from "react-multi-select-component";
import Swal from "sweetalert2";
import Select from "react-select";

export const PopUpMessage = async (Message, popUpType) => {
  return await Swal.fire({
    title: Message,
    icon: popUpType,
    timer: 2000,
    showConfirmButton: false,
    customClass: {
      title: 'custom-title',
      popup: 'custom-popup',
      icon: 'custom-icon'
    },

  });
};

export const CMDropDown = ({
  InputLabel,
  options,
  Multiple,
  requiredStar,
  AvoidFormInputCss,
  disablehandle,
  NoLabel,
  handleError,
  placeholder,
  id,
  IsFormikInput,
  valueTempt,
  handleDispatch
}) => {
  return (
    <>
      <div className={`form-group   ${AvoidFormInputCss ? "" : "formgroupcss"}`}>
        {!NoLabel && <label style={{ color: "black" }} >{InputLabel}</label>}
        {requiredStar && <span className="text-danger ml-1">*</span>}
        {Multiple ? <MultiSelect
          options={[...options]}
          value={valueTempt || []}
          // value={Multiple ? valueTempt :  options?.find((option) => option.value == valueTempt)}
          onChange={(e) => { IsFormikInput ? handleDispatch(e) : handleDispatch(e, id) }}
          labelledBy="Select"
        /> : <Select
          id={id}
          className={disablehandle ? "" : ""}
          name={id}
          placeholder={placeholder || "Select..."}
          //   placeholder
          isDisabled={disablehandle || false}
          isMulti={Multiple ? true : false}
          options={[...options]}
          value={Multiple ? valueTempt : options?.find((option) => option.value == valueTempt)}
          onChange={(e) => { IsFormikInput ? handleDispatch(e) : handleDispatch(e, id) }}
        />}
        {
          handleError &&
          handleError()
        }
      </div>

    </>
  );
};

export const CMFormInput = ({
  InputLabel,
  id,
  onKeyDown,
  type,
  accept,
  NoLabel,
  LabelExample,
  textArea,
  oldImage,
  maxLength,
  temptVal,
  disabled,
  handleError,
  AvoidFormInputCss,
  row,
  col,
  required,
  imgReg,
  handleDispatch,
  onPaste
}) => {
  return <div className={`form-group   ${AvoidFormInputCss ? "" : "formgroupcss"}`}>
    {!NoLabel ? <label style={{ color: "black" }}>
      {InputLabel} {LabelExample ? <span style={{ color: "gray" }}>{LabelExample}</span> : ""}
      {required && <span className="text-danger ml-1">*</span>}
    </label> : ""}
    {textArea ?
      <textarea
        onKeyDown={onKeyDown || null}
        onChange={(e) => { handleDispatch(e) }}
        id={id}
        rows={row}
        cols={col}
        value={temptVal}
        accept={accept || ""}
        onPaste={onPaste || null}
        placeholder={`Enter ${InputLabel}`}
        className="form-control AllowAlphabetSpaceKey"
        maxLength={maxLength}
        name={id}
      /> : <><input
        onKeyDown={onKeyDown || null}
        onChange={(e) => { handleDispatch(e) }}
        id={id}
        ref={imgReg || null}
        disabled={disabled}
        value={temptVal}
        accept={accept || null}
        type={type}
        onPaste={onPaste || null}

        placeholder={`Enter ${InputLabel}`}
        className="form-control AllowAlphabetSpaceKey"
        maxLength={maxLength}
        name={id}
        autocomplete={"off"}
      />
        <span>{oldImage || ""}</span>
      </>}

    {handleError && handleError(id)}
  </div>
}

export const MultiSelectRadio = ({ options, onChange }) => {
  return (
    <div className="row">
      {options.map((option, index) => (
        <div key={option.id} className="col-md-4 text-left">
          <input
            type="checkbox"
            id={option.id}
            value={option.id}
            checked={option.checked}
            onChange={() => onChange(index)}
          />
          <label style={{ position: "relative", bottom: "2.5px", left: "3px" }}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export const RadioButtonList = ({ options, name, selectedValue, onChange, value }) => {
  return (
    <div className="radio-group form-control">
      {options.map(option => (
        <div key={option.value} className="radio-item">
          <label>
            <input
              type="radio"
              name={name}
              value={option.value}
              // checked={selectedValue === option.value}
              checked={value === option.value}
              // onChange={() => onChange(option.value)}
              onChange={onChange}
            />
            &nbsp;{option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export const handleError = (formik, name) => {
  return formik.touched[name] && formik.errors[name] ? (
    <div style={{ color: "red", fontWeight: "700", fontSize: "1em" }}>{formik.errors[name]}</div>
  ) : null
}
